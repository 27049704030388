import React, { lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingFallback } from "./reactComponents/utils/utilities";

const LoginPage = lazy(() => import("./reactComponents/loginPage"));
const LoginVerificationPage = lazy(() =>
  import("./reactComponents/loginVerificationPage")
);
const DashboardRoutes = lazy(() => import("./routes/dashboardRoutes"));

/**  ********************************************
       App Component  
 ********************************************  */
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const mobileNumber = localStorage.getItem("mobileNumber");

  useEffect(() => {
    if (accessToken && location.pathname === "/") {
      navigate("/dashboard");
    } else if (!accessToken && !mobileNumber && location.pathname !== "/") {
      navigate("/");
    }
  }, [accessToken, location.pathname]);
  return (
    <div>
      {/* <Router> */}
      <Routes>
        <Route exact path="/" element={<Suspense fallback={<LoadingFallback />}>
        <LoginPage />
          </Suspense>}></Route>
        <Route
          exact
          path="/otpverificationpage"
          element={<Suspense fallback={<LoadingFallback />}>
          <LoginVerificationPage />
            </Suspense>}
        ></Route>

        <Route path="/*" element={<DashboardRoutes />}></Route>
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* </Router> */}
    </div>
  );
};

export default App;
