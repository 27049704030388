export const actionTypes = {
  SET_MOBILENO: "SET_MOBILENO",
  SET_ACCESSTOKEN: "SET_ACCESSTOKEN",
  OTP_VERIFY: "OTP_VERIFY",
  GET_SOFT_STATISTICS: "GET_SOFT_STATISTICS",
  MOBILE_EMAIL_VERIFICATION: "MOBILE_EMAIL_VERIFICATION",
  MOBILE_VERIFICATION: "MOBILE_VERIFICATION",
  FETCH_ACTION_FAIL: "FETCH_ACTION_FAIL",
  FETCH_OPERATOR_AIRCRAFTS_DETAILS: "FETCH_OPERATOR_AIRCRAFTS_DETAILS",
  FETCH_OPERATOR_DGCA_DETAILS: "FETCH_OPERATOR_DGCA_DETAILS",
  FETCH_INDIVIDUAL_OPERATOR_DETAILS: "FETCH_INDIVIDUAL_OPERATOR_DETAILS",
  FETCH_INDIVIDUAL_AGENT_DETAILS: "FETCH_INDIVIDUAL_AGENT_DETAILS",
  GET_INDIVIDUAL_LONG_LEASE_DETAILS: "GET_INDIVIDUAL_LONG_LEASE_DETAILS",
  FETCH_SEARCH_LONG_LEASE_AIRCRAFTS_DETAILS:
  "FETCH_SEARCH_LONG_LEASE_AIRCRAFTS_DETAILS",
  FETCH_INDIVIDUAL_OPERATOR_AIRCRAFTS_DETAILS:
    "FETCH_INDIVIDUAL_OPERATOR_AIRCRAFTS_DETAILS",
  POST_SEND_LONG_LEASE_ENQUIRY_FARE_DETAILS:
    "POST_SEND_LONG_LEASE_ENQUIRY_FARE_DETAILS",
  FETCH_INDIVIDUAL_OPERATOR_DGCA_AIRCRAFTS_DETAILS:
    "FETCH_INDIVIDUAL_OPERATOR_DGCA_AIRCRAFTS_DETAILS",
    
  FETCH_GUEST_DETAILS: "FETCH_GUEST_DETAILS",
  FETCH_PASSANGERS_DETAILS: "FETCH_PASSANGERS_DETAILS",
  FETCH_OPERATOR_DETAILS: "FETCH_OPERATOR_DETAILS",
  FETCH_AIRCRAFTS_DETAILS: "FETCH_AIRCRAFTS_DETAILS",
  FETCH_ALL_DGCA_OPERATORS_DETAILS: "FETCH_ALL_DGCA_OPERATORS_DETAILS",
  SET_ADD_OPERATOR_DETAILS: "SET_ADD_OPERATOR_DETAILS",
  FETCH_ONBOARDED_STAFF_DETAILS: "FETCH_ONBOARDED_STAFF_DETAILS",
  FETCH_ONBOARDED_AGENTS_DATA: "FETCH_ONBOARDED_AGENTS_DATA",
  FETCH_NSOP_OPERATOR_DETAILS: "FETCH_NSOP_OPERATOR_DETAILS",
  SET_ADD_AGGREGATOR_STAFF_DETAILS: "SET_ADD_AGGREGATOR_STAFF_DETAILS",
  SET_DELETE_AGGREGATOR_STAFF_DETAILS: "SET_DELETE_AGGREGATOR_STAFF_DETAILS",
  SET_EDIT_AGGREGATOR_STAFF_DETAILS: "SET_EDIT_AGGREGATOR_STAFF_DETAILS",
  SET_ADD_AGENT_DETAILS: "SET_ADD_AGENT_DETAILS",
  GET_LONG_LEASE_ENQUIRIES: "GET_LONG_LEASE_ENQUIRIES",
  FETCH_ONBOARDED_OPERATOR_QUOTES: "FETCH_ONBOARDED_OPERATOR_QUOTES",
  PATCH_AGENTS_STATUS: "PATCH_AGENTS_STATUS",
  PATCH_OPERATOR_STATUS: "PATCH_OPERATOR_STATUS",
  FETCH_INDIVIDUAL_AIRCRAFT_DETAILS: "FETCH_INDIVIDUAL_AIRCRAFT_DETAILS",
  GET_OPERATOR_ENQUIRIES: "GET_OPERATOR_ENQUIRIES",
  GET_INDIVIDUAL_BOOKINGS: "GET_INDIVIDUAL_BOOKINGS",
  PATCH_AIRCRAFT_STATUS: "PATCH_AIRCRAFT_STATUS",
  FETCH_ONBOARDED_AIRCRAFTS_DETAILS: "FETCH_ONBOARDED_AIRCRAFTS_DETAILS",
  FETCH_SEARCH_AIRCRAFTS_DETAILS: "FETCH_SEARCH_AIRCRAFTS_DETAILS",
  FETCH_SEARCH_AIRPORTS_DETAILS: "FETCH_SEARCH_AIRPORTS_DETAILS",
  FETCH_ALL_PAYMENT_DETAILS: "FETCH_ALL_PAYMENT_DETAILS",
  PATCH_OPERATOR_DOCUMENTS: "PATCH_OPERATOR_DOCUMENTS",
  PATCH_BOARDOFDIRECTORS: "PATCH_BOARDOFDIRECTORS",
  PATCH_BANKDETAILS: "PATCH_BANKDETAILS",
  PATCH_OPERATOR_DETAILS: "PATCH_OPERATOR_DETAILS",
  PATCH_AGENT_DOCUMENTS: "PATCH_AGENT_DOCUMENTS",
  PATCH_AGENT_DETAILS: "PATCH_AGENT_DETAILS",
  FETCH_SOFT_PAYMENTS: "FETCH_SOFT_PAYMENTS",
  FETCH_FERRY_AND_IDEAL_DAYS: "FETCH_FERRY_AND_IDEAL_DAYS",
  POST_AIRCRAFT_DETAILS: "POST_AIRCRAFT_DETAILS",
  UPDATE_PAYMENT_VERIFICATION_STATUS: "UPDATE_PAYMENT_VERIFICATION_STATUS",
  PATCH_AIRCRAFT_DETAILS: "PATCH_AIRCRAFT_DETAILS",
  POST_ADD_PASSENGER_DETAILS: "POST_ADD_PASSENGER_DETAILS",
  POST_ADD_PASSENGER_GROUP_DETAILS: "POST_ADD_PASSENGER_GROUP_DETAILS",
  GET_PASSENGER_GROUP_DETAILS: "GET_PASSENGER_GROUP_DETAILS",
  PATCH_PASSENGER_DETAILS: "PATCH_PASSENGER_DETAILS",
  PATCH_PASSENGER_GROUP_DETAILS: "PATCH_PASSENGER_GROUP_DETAILS",
  DELETE_PASSENGER_DETAILS: "DELETE_PASSENGER_DETAILS",
  GET_CREW_DETAILS: "GET_CREW_DETAILS",
  POST_CREW_DETAILS: "POST_CREW_DETAILS",
  PATCH_DELETE_CREW_DETAILS: "PATCH_DELETE_CREW_DETAILS",
  POST_GET_QUOTE: "POST_GET_QUOTE",
  PATCH_AIRCRAFT_VERIFICATION: "PATCH_AIRCRAFT_VERIFICATION",
  PATCH_CREW_DETAILS: "PATCH_CREW_DETAILS",
  POST_SEND_ENQUIRY_FARE_DETAILS: "POST_SEND_ENQUIRY_FARE_DETAILS",
  PATCH_REJECT_BOOKING: "PATCH_REJECT_BOOKING",
  GET_INDIVIDUAL_TICKETS: "GET_INDIVIDUAL_TICKETS",
  GET_INDIVIDUAL_TICKET_DETAILS: "GET_INDIVIDUAL_TICKET_DETAILS",
  POST_GO_LIVE: "POST_GO_LIVE",
  POST_ASSIGN_CREW: "POST_ASSIGN_CREW",
  POST_INDIVIDUAL_TICKET_COMMENT: "POST_INDIVIDUAL_TICKET_COMMENT",
  POST_ASSIGN_CREW: "POST_ASSIGN_CREW",
  POST_GO_LIVE: "POST_GO_LIVE",
  GET_WALLET_DETAILS: "GET_WALLET_DETAILS",
  POST_WALLET_DETAILS: "POST_WALLET_DETAILS",
  PATCH_TICKET_STATUS: "PATCH_TICKET_STATUS",
  GET_AIRCRAFT_LIVE_LOCATION: "GET_AIRCRAFT_LIVE_LOCATION",
  POST_PAYMENT_CHECKOUT: "POST_PAYMENT_CHECKOUT",
  GET_CHAT_ROOMS: "GET_CHAT_ROOMS",
  GET_CHAT_ROOM_DATA: "GET_CHAT_ROOM_DATA",
  GET_NOTIFICATIONS_LOG: "GET_NOTIFICATIONS_LOG",
  POST_BOOKING_ADD_PASSENGERS: "POST_BOOKING_ADD_PASSENGERS",
  PATCH_VERIFY_PASSENGERS: "PATCH_VERIFY_PASSENGERS",
  PATCH_CANCEL_BOOKING: "PATCH_CANCEL_BOOKING",
  FETCH_UPCOMING_BOOKING: "FETCH_UPCOMING_BOOKING",
  FETCH_GLOBAL_CONSTANTS: "FETCH_GLOBAL_CONSTANTS",
  GET_OPERATOR_SETTINGS: "GET_OPERATOR_SETTINGS",
  PATCH_REVIEW_FERRY_LEG: "PATCH_REVIEW_FERRY_LEG",
  PATCH_INTRESTED_FERRY: "PATCH_INTRESTED_FERRY",
  PATCH_PAY_ACTIVATED_USERS: "PATCH_PAY_ACTIVATED_USERS",
  BOOKING_PUSH_NOTIFICATIONS: "BOOKING_PUSH_NOTIFICATIONS",
  CUSTOMER_QUOTE_REQUEST_INFO: "CUSTOMER_QUOTE_REQUEST_INFO",
  UPDATE_BOOKING_LEG: "UPDATE_BOOKING_LEG",
  CUSTOMER_STATISTICS_REPORTS: "CUSTOMER_STATISTICS_REPORTS",
  POST_PAYMENT_REMINDER: "POST_PAYMENT_REMINDER",
  POST_AIRCRAFT_AVAILIBILITY: "POST_AIRCRAFT_AVAILIBILITY",
  GET_PASSENGER_REMINDER: "GET_PASSENGER_REMINDER",
  POST_DELETE_OPERATOR_AGENT: "POST_DELETE_OPERATOR_AGENT",
  POST_TECH_HALT_DETAILS: "POST_TECH_HALT_DETAILS",
  AWS_CREDENTIALS: "AWS_CREDENTIALS",
  CURRENCY_CONVERTER: "CURRENCY_CONVERTER",
};
